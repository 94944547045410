import { DropdownItem } from '@/types/common'

import { BannerType, CategoryInformation, ControlStatus, ProgramStep, ProgramType, SortBy, SubtitleType } from './enum'
import { Language, NotificationBroadcastType } from './enum'

import addFilm from '/assets/images/add-film.webp'
import addSeason from '/assets/images/add-season.webp'

export const emailRegex = /^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]{2,}\.(co(\.[a-zA-Z]{2,})?|com|org|app)$/
export const episodeDuration = /^\d{2}:\d{2}:\d{2}$/
export const phoneNumberRegex = /^\+?[1-9]\d{7,14}$/
export const urlRegex = /^(https?:\/\/)?([a-zA-Z0-9-]+\.)+[a-zA-Z]{2,}(:\d+)?(\/[^\s]*)?$/

export const bannerTypeItems = [
  {
    id: BannerType.PROGRAM,
    label: 'Program',
  },
  {
    id: BannerType.URL_SITE,
    label: 'Informasi',
  },
]

export const sortByItems: DropdownItem[] = [
  { id: String(SortBy.LATEST), label: 'Terbaru' },
  { id: String(SortBy.EARLIEST), label: 'Terlama' },
]

export const notificationBroadcastTypeItems = [
  {
    id: String(NotificationBroadcastType.INFORMATION),
    label: 'Informasi',
  },
  {
    id: String(NotificationBroadcastType.PROGRAM),
    label: 'Program',
  },
]

export const languageOptions = [
  {
    id: Language.Indonesia,
    label: 'Indonesia',
  },
  {
    id: Language.English,
    label: 'English',
  },
]

export const controlStatusOptions = [
  {
    id: ControlStatus.ACTIVE.toString(),
    label: 'ACTIVE',
  },
  {
    id: ControlStatus.INACTIVE.toString(),
    label: 'INACTIVE',
  },
]

export const programTypeItems = [
  {
    id: ProgramType.SINGLE.toString(),
    label: 'Sinema',
    description: 'Tipe program yang mengakomodasi hanya 1 konten.',
    image: addSeason,
  },
  {
    id: ProgramType.SEASON.toString(),
    label: 'Serial / Episode',
    description: 'Tipe program yang dapat mengakomodasi lebih dari 1 konten.',
    image: addFilm,
  },
]

export const programSteps = [
  {
    id: ProgramStep.GENERAL,
    path: 'general',
    label: 'Data Umum',
  },
  {
    id: ProgramStep.VIDEO,
    path: 'video',
    label: 'Data Video',
  },
  {
    id: ProgramStep.OTHER,
    path: 'other-information',
    label: 'Info Lainnya',
  },
  {
    id: ProgramStep.COVER,
    path: 'cover',
    label: 'Cover & Trailer',
  },
  {
    id: ProgramStep.PUBLICATION,
    path: 'publication',
    label: 'Publikasi',
  },
]

export const categoriesInformation = [
  {
    id: CategoryInformation.CAST.toString(),
    label: 'Pemeran',
  },
  {
    id: CategoryInformation.FIGURE.toString(),
    label: 'Tokoh',
  },
  {
    id: CategoryInformation.DIRECTOR.toString(),
    label: 'Sutradara',
  },
  {
    id: CategoryInformation.SUBJECT.toString(),
    label: 'Subjek',
  },
  {
    id: CategoryInformation.GUEST_SPEAKER.toString(),
    label: 'Narasumber',
  },
  {
    id: CategoryInformation.OTHER.toString(),
    label: 'Lainnya',
  },
]

export const categoriesInformationWillChecked = [
  CategoryInformation.CAST.toString(),
  CategoryInformation.FIGURE.toString(),
  CategoryInformation.DIRECTOR.toString(),
  CategoryInformation.SUBJECT.toString(),
  CategoryInformation.GUEST_SPEAKER.toString(),
]

export const ProgramTypeWillChecked = [ProgramType.SINGLE, ProgramType.SEASON]

export const subtitlesType = [
  {
    id: SubtitleType.UNKNOWN_SUBTITLE_TYPE.toString(),
    label: '-',
  },
  {
    id: SubtitleType.INDONESIA.toString(),
    label: 'indonesia',
  },
  {
    id: SubtitleType.ENGLISH.toString(),
    label: 'English',
  },
  {
    id: SubtitleType.SDH.toString(),
    label: 'SDH',
  },
]

export const controlStatus = [
  { id: ControlStatus.ACTIVE.toString(), label: 'Aktif' },
  { id: ControlStatus.INACTIVE.toString(), label: 'Tidak Aktif' },
]
