import { LIVE_STREAM_PROGRAMS } from '@/configs/endpoint'
import { ControlStatus } from '@/configs/enum'
import { BaseListQueryRequest, BaseListResponse, ModifiedBy, TimestampAndVersion } from '@/types/common'
import { api } from '@/utils/api'

export type GetListLiveStreamProgramsRequestParams = {
  xid: string
}

export type GetListLiveStreamProgramsRequestQuery = BaseListQueryRequest<{
  name?: string
  startDate?: string
  endDate?: string
}>

export type GetListLiveStreamProgramsRequest = {
  params?: GetListLiveStreamProgramsRequestParams
  query?: GetListLiveStreamProgramsRequestQuery
}

export type GetListLiveStreamProgramsResponse = BaseListResponse<
  {
    xid: string
    name: string
    startDate: string
    endDate: string
    liveStreamXid: string
    duration: string
    statusId: ControlStatus
    modifiedBy: ModifiedBy
  } & TimestampAndVersion
>

export const getListLiveStreamPrograms = async (request: GetListLiveStreamProgramsRequest) => {
  const response = await api<
    GetListLiveStreamProgramsResponse,
    GetListLiveStreamProgramsRequestParams,
    GetListLiveStreamProgramsRequestQuery
  >({
    ...request,
    url: LIVE_STREAM_PROGRAMS,
    method: 'GET',
  })

  return response.data
}

export const getListLiveStreamProgramsKey = 'GET_LIST_LIVE_STREAM_PROGRAMS'
