import { CSSObject, keyframes } from '@emotion/react'
import styled from '@emotion/styled'
import { FC, SyntheticEvent, useMemo, useState } from 'react'

import { ImagePreview } from '../Image'

import defaultImage12 from '/assets/images/default-image-1-2.webp'
import defaultImage34 from '/assets/images/default-image-3-4.webp'
import defaultImage169 from '/assets/images/default-image-16-9.webp'

type StyledProps = {
  width?: number
  height?: number
}

const shimmerKeyframes = keyframes`
    0% {
        background-position: -200% 0;
    }
    100% {
        background-position: 200% 0;
    }
`

const StyledWrapper = styled.div<StyledProps>(
  ({ width = '80px', height = '45px' }): CSSObject => ({
    width,
    height,
    position: 'relative',
    borderRadius: '4px',
    overflow: 'hidden',
    background: `linear-gradient(
      90deg,
      #f0f0f0 25%,
      #e0e0e0 50%,
      #f0f0f0 75%
    )`,
    backgroundSize: '200% 100%',
    animation: `${shimmerKeyframes} 1.5s infinite linear`,
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
  })
)

const StyledImage = styled.img<StyledProps>(
  ({ width = '80px', height = '45px' }): CSSObject => ({
    width,
    height,
    objectFit: 'cover',
    objectPosition: 'center',
    borderRadius: '4px',
    position: 'absolute',
    top: 0,
    left: 0,
    opacity: 0,
    transition: 'opacity 0.3s ease-in-out',
  })
)

export type TableImageProps = {
  src: string
  alt: string
  preview?: boolean
  closeOnBackdropClick?: boolean
  defaultImage?: '1:2' | '3:4' | '16:9'
} & StyledProps

export const TableImage: FC<TableImageProps> = ({
  src,
  alt,
  preview = true,
  closeOnBackdropClick = true,
  defaultImage = '16:9',
  ...styledProps
}) => {
  const [openPreview, setOpenPreview] = useState(false)

  const handleImageLoad = (e: SyntheticEvent<HTMLImageElement>) => {
    const image = e.currentTarget
    image.style.opacity = '1' // Show the image once it has loaded
  }

  const handleOpenPreview = () => {
    if (preview) {
      setOpenPreview(true)
    }
  }

  const srcImage = useMemo(() => {
    if (src.length) {
      return src
    }
    if (defaultImage === '1:2') {
      return defaultImage12
    }
    if (defaultImage === '3:4') {
      return defaultImage34
    }
    return defaultImage169
  }, [src])

  return (
    <>
      <div
        onClick={handleOpenPreview}
        css={{ cursor: 'pointer', width: 'fit-content' }}
      >
        <StyledWrapper {...styledProps}>
          <StyledImage
            src={srcImage}
            alt={alt}
            onLoad={handleImageLoad}
            {...styledProps}
          />
        </StyledWrapper>
      </div>

      {openPreview && (
        <ImagePreview
          isOpen={openPreview}
          onClose={() => setOpenPreview(false)}
          closeOnBackdropClick={closeOnBackdropClick}
          slides={[{ src: srcImage }]}
        />
      )}
    </>
  )
}
