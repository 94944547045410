// Auth
export const ANONYMOUS = '/v1/admins/anon/sessions'
export const LOGIN = '/v1/admin/sessions'
export const LOGOUT = '/v1/admin/sessions'
export const FORGOT_PASSWORD = '/v1/admin/accounts/forgot-password'
export const REFRESH_TOKEN = '/v1/admin/sessions/refresh'
export const RESET_PASSWORD = '/v1/admin/accounts/verify-password'

// Asset
export const UPLOAD_URL = '/v1/assets/upload-url'

// Program
export const PROGRAM = '/v1/admin/programs'
export const PROGRAM_DETAIL = '/v1/admin/programs/:xid'

// Program Episode
export const PROGRAM_EPISODE = '/v1/admin/programs/:xid/episodes'
export const PROGRAM_EPISODE_SEASON = '/v1/admin/programs/:programXid/seasons/:seasonXid/episodes'
export const PROGRAM_EPISODE_DETAIL = '/v1/admin/programs/:programXid/episodes/:episodeXid'
export const PROGRAM_EPISODE_DETAIL_GENERAL = '/v1/admin/programs/:programXid/episodes/:episodeXid/general'
export const PROGRAM_EPISODE_DETAIL_OTHER = '/v1/admin/programs/:programXid/episodes/:episodeXid/other'
export const PROGRAM_EPISODE_DETAIL_ATTACHMENT = '/v1/admin/programs/:programXid/episodes/:episodeXid/attachment'
export const PROGRAM_EPISODE_CREATE = '/v1/admin/programs/:programXid/episodes'
export const PROGRAM_EPISODE_CHANGE_SEQUENCE = '/v1/admin/programs/:programXid/episodes/:xid'

// Program Episode Session
export const PROGRAM_EPISODE_SESSION = '/v1/admin/programs/:xid/seasons'
export const PROGRAM_EPISODE_SESSION_DETAIL = '/v1/admin/programs/:xid/seasons/:seasonXid'

// Notification Broadcasts
export const NOTIFICATION_BROADCASTS = '/v1/admin/notification-broadcasts'
export const NOTIFICATION_BROADCASTS_DETAIL = '/v1/admin/notification-broadcasts/:xid'
export const NOTIFICATION_BROADCASTS_CHANGE_STATUS = '/v1/admin/notification-broadcasts/:xid/status'
export const NOTIFICATION_BROADCASTS_SEND_NOW = '/v1/admin/notification-broadcasts/:xid/publish'

// Banner
export const BANNER = '/v1/admin/banners'
export const BANNER_DETAIL = '/v1/admin/banners/:xid'
export const BANNER_CHANGE_STATUS = '/v1/admin/banners/:xid/status'
export const BANNER_CHANGE_SEQUENCE = '/v1/admin/banners/:xid/sequence'
export const BANNER_PUBLISH = '/v1/admin/banners/:xid/publish'

// Live Streams
export const LIVE_STREAMS = '/v1/admin/live-streams'
export const LIVE_STREAMS_DETAIL = '/v1/admin/live-streams/:xid'
export const LIVE_STREAM_PROGRAMS = '/v1/admin/live-streams/:xid/programs'
export const LIVE_STREAM_STATUS = '/v1/admin/live-streams/:xid/status'

// Category
export const CATEGORY = '/v1/admin/top-level-categories'
export const CATEGORY_DETAIL = '/v1/admin/top-level-categories/:xid'

// Sub Category
export const SUB_CATEGORY = '/v1/admin/categories'
export const SUB_CATEGORY_DETAIL = '/v1/admin/categories/:xid'

// Cast
export const CAST = '/v1/admin/cast'
export const CAST_DETAIL = '/v1/admin/cast/:xid'

// Figure
export const FIGURE = '/v1/admin/figures'
export const FIGURE_DETAIL = '/v1/admin/figures/:xid'

// Director
export const DIRECTOR = '/v1/admin/directors'
export const DIRECTOR_DETAIL = '/v1/admin/directors/:xid'

// Age Rating
export const AGE_RATING = '/v1/admin/age-ratings'
export const AGE_RATING_DETAIL = '/v1/admin/age-ratings/:xid'
// Users App
export const USERS_APP = '/v1/admin/user-apps'
export const USERS_APP_DETAIL = '/v1/admin/user-apps/:xid'

// Sub Roles
export const SUB_ROLES = '/v1/admin/sub-roles'
export const SUB_ROLES_DETAIL = '/v1/admin/sub-roles/:xid'

// Users CMS
export const USERS_CMS = '/v1/admin/users-cms'
export const USERS_CMS_DETAIL = '/v1/admin/users-cms/:xid'
export const USERS_CMS_CREATE = '/v1/admin/registration'
export const USERS_CMS_EMAIL_ACTIVATION = '/v1/admin/email-activation'
export const USERS_CMS_CHANGE_STATUS = '/v1/admin/users-cms/:xid/status'

// Profile
export const PROFILE = '/v1/admin/sessions/me'

// Dynamic Splash Screen
export const DYNAMIC_SPLASH_SCREEN = '/v1/admin/splash-screens'
export const DYNAMIC_SPLASH_SCREEN_DETAIL = '/v1/admin/splash-screens/:xid'

// Other Information
export const TERM_CONDITION = '/v1/term-condition'
export const PRIVACY_POLICY = '/v1//privacy-policy'
export const FEEDBACK_URL = '/v1/feedback-url'

// StaticSplashScreen
export const STATIC_SPLASH_SCREEN = '/v1/admin/static/splash-screens'

// Dynamic Content
export const DYNAMIC_CONTENT = '/v1/admin/home-sections'
export const DYNAMIC_CONTENT_DETAIL = '/v1/admin/home-sections/:xid/detail'
export const DYNAMIC_CONTENT_UPDATE = '/v1/admin/home-sections/:xid/update'
export const DYNAMIC_CONTENT_DELETE = '/v1/admin/home-sections/:xid'
export const DYNAMIC_CONTENT_CHANGE_SEQUENCE = '/v1/admin/home-sections/sequence'
export const DYNAMIC_CONTENT_CHANGE_STATUS = '/v1/admin/home-sections/:xid/status'
export const DYNAMIC_CONTENT_EPISODE_LIST = '/v1/admin/home-sections/episodes/:xid'

// Program Submission
export const PROGRAM_SUBMISSION_DRAFT = '/v1/admin/programs-draft'
export const PROGRAM_SUBMISSION_DRAFT_DETAIL = '/v1/admin/programs-draft/:xid'
export const PROGRAM_SUBMISSION_DRAFT_GENERAL_INFORMATION = '/v1/admin/programs-draft/:xid/general-information'
export const PROGRAM_SUBMISSION_DRAFT_OTHER_INFORMATION = '/v1/admin/programs-draft/:xid/other-information'
export const PROGRAM_SUBMISSION_CHANGE_STATUS = '/v1/admin/programs-draft/:xid/status'

export const PROGRAM_SUBMISSION_DRAFT_VIDEO_SESSION = '/v1/admin/programs-draft/:xid/seasons'
export const PROGRAM_SUBMISSION_DRAFT_VIDEO_SESSION_DETAIL = '/v1/admin/programs-draft/:xid/seasons/:seasonXid'

export const PROGRAM_SUBMISSION_DRAFT_EPISODE = '/v1/admin/programs-draft/:xid/episodes'
export const PROGRAM_SUBMISSION_DRAFT_EPISODE_MULTIPLE = '/v1/admin/programs-draft/:xid/seasons/:seasonXid/episodes'
export const PROGRAM_SUBMISSION_DRAFT_EPISODE_DETAIL = '/v1/admin/programs-draft/:xid/episodes/:episodeXid'
export const PROGRAM_SUBMISSION_DRAFT_EPISODE_SEQUENCE =
  '/v1/admin/programs-draft/:xid/seasons/:seasonXid/episodes/:episodeXid/sequence'

export const PROGRAM_APPROVE = '/v1/admin/programs-draft/:xid/approve'
export const PROGRAM_REJECT = '/v1/admin/programs-draft/:xid/reject'

// Kumlate
export const KUMULATE_CONTENT = '/v1/admin/kumulate-contents'
export const KUMULATE_CONTENT_DETAIL = '/v1/admin/kumulate-contents/:xid'

// Trailer
export const TRAILER = '/v1/admin/:type/:xid/trailers'

// Tags
export const TAG = '/v1/admin/tags'
export const TAG_DETAIL = '/v1/admin/tags/:xid'

// Notification
export const NOTIFICATION = '/v1/admin/notifications'
export const NOTIFICATION_READ = '/v1/admin/notifications/:xid/read'
export const NOTIFICATION_READ_ALL = '/v1/admin/notifications/read-all'

// Program Coverage
export const PROGRAM_COVERAGE = '/v1/admin/program-coverages'
export const PROGRAM_COVERAGE_DETAIL = '/v1/admin/program-coverages/:xid'
export const PROGRAM_COVERAGE_CHANGE_STATUS = '/v1/admin/program-coverages/:xid/status'

export const HIGHLIGHTS = '/v1/admin/highlights'

// Slide Show
export const SLIDE_SHOWS = '/v1/admin/slide-shows'
export const SLIDE_SHOWS_DETAIL = '/v1/admin/slide-shows/:xid'
export const SLIDE_SHOWS_CHANGE_STATUS = '/v1/admin/slide-shows/:xid/status'
export const SLIDE_SHOWS_CHANGE_SEQUENCE = '/v1/admin/slide-shows/:xid/sequence'

// Subjects
export const SUBJECT = '/v1/admin/subjects'
export const SUBJECT_DETAIL = '/v1/admin/subjects/:xid'

// guest-speakers
export const SPEAKER = '/v1/admin/guest-speakers'
export const SPEAKER_DETAIL = '/v1/admin/guest-speakers/:xid'
